.login-main-container {
  display: flex;
  min-height: 100vh;
  align-items: center;
}

.login-wrapper {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.login-form {
  min-width: 350px;
  padding: 20px;
  box-shadow: 0 6px 4px 0 rgba(0,0,0,.16);
  background-color: white;
}

.logo-wrapper-dav {
  display: flex;
  justify-content: center;
}

.logo-wrapper-dav img {
  width: 250px;
  height: 100px
}

.login-form input {
  margin-top: 10px;
  height: 45px;
}

.buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.btn-login {
  width: 100%;
  margin-top: 10px;
  height: 45px;
}

.btn-reset {
  width: 100%;
  margin-top: 10px;
  height: 45px;
}

.construction-banner {
  position: absolute;
  top: 0px;
  width: 100%;
  margin-left: -15px;
  margin-right: -15px;
}

.construction-alert {
  padding:40px 40px 40px 40px;
}