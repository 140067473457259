.create-wrapper-job {
    position: relative;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px 0px;
    background-color: white;
    margin-bottom: 20px;
    max-width: 750px;
}

.terms-row {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
}

.terms-row p {
    margin: 0px;
}

.submit-wrapper {
    width: 100%;
}

.submit-button {
    width: 150px;
}
