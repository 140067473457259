.create-wrapper-settings {
  position: relative;
    padding: 20px;
    max-height: 750px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px 0px;
    min-width: 850px;
    background-color: white;
}

.option-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.option {
  position: relative;
  border-radius: 20px;
  background-color: rgb(43, 114, 221);
  color: white;
  margin: 5px;
  padding: 5px 10px 5px 10px;
}

.option p {
  margin: 0px;
  font-size: 13px;
}

.delete-icon {
  position: absolute;
  top: -10px;
  right: 0px;
  color: rgb(218, 20, 20);
  cursor: pointer;
}

.input-wrapper {
  position: relative;
}

.add-icon {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  color: green;
}