@import url('https://fonts.googleapis.com/css?family=Open+Sans|Poppins:400,700&display=swap');

body {
    background-color: #F5F5F5;
    position: relative;
}

.loading-screen {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

p {
    font-family: 'Open Sans', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
}