.main-nav-container {
  position: fixed;
  left: 0px;
  top: 0px;
  min-height: 100vh;
  background-color: #818285;
  width: 66px;
  z-index: 3;
  
}

.navitem-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  padding: 5px;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
}

.nav-active {
  color: #EE2E24 !important;
}

.navitem-wrapper p {
  font-size: 13px;
  margin: 0px;
}

.navitem-wrapper:hover {
  background-color: #ee2e2447;
}

.pop-menu {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: absolute;
  z-index: 1000;
  background-color: #818285;
  top: 0px;
  right: -150px;
  height: 85px;
  width: 150px;
  padding: 10px;
  color: white;
}

.pop-menu-item {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  font-size: 13px;
  padding: 5px;
  align-content: center;
  align-items: center;
}

.pop-menu-item:hover {
  background-color: #ee2e2447;
}

.pop-menu-item p {
  margin: 0px 0px 0px 5px;
  vertical-align: middle;
}

.logout {
  position: absolute;
  bottom: 0px;
}

.top-bar-wrapper {
  position: fixed;
  min-width: 100%;
  top: 0px;
  height: 50px;
  display:flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  border-bottom: solid rgba(51, 51, 51, 0.4) 1px;
  z-index: 2;
  background-color: #F5F5F5;
}

.top-bar-wrapper p {
  margin: 0 60px 0px 10px;
}

.top-details-wrapper {
  display: flex;
  align-items: center;
}