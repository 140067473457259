.main-container {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  padding-top: 65px;
}

.create-wrapper {
  position: relative;
  padding: 20px;
  max-height: 600px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px 0px;
  min-width: 450px;
  background-color: white;
  max-width: 450px;
}

.days-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.day-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 5px 5px 5px 5px;
  width: 80px;
}

label {
  color: #333;
  font-size: 13px;
  font-weight: 600;
  margin-top: 10px;
}

.days-title {
  width: 100%;
}

.button-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 20px;
}

.btn-create {
  width: 150px;
}