@import url(https://fonts.googleapis.com/css?family=Open+Sans|Poppins:400,700&display=swap);
.main-nav-container {
  position: fixed;
  left: 0px;
  top: 0px;
  min-height: 100vh;
  background-color: #818285;
  width: 66px;
  z-index: 3;
  
}

.navitem-wrapper {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  color: white;
  padding: 5px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
}

.nav-active {
  color: #EE2E24 !important;
}

.navitem-wrapper p {
  font-size: 13px;
  margin: 0px;
}

.navitem-wrapper:hover {
  background-color: #ee2e2447;
}

.pop-menu {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  position: absolute;
  z-index: 1000;
  background-color: #818285;
  top: 0px;
  right: -150px;
  height: 85px;
  width: 150px;
  padding: 10px;
  color: white;
}

.pop-menu-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  width: 100%;
  font-size: 13px;
  padding: 5px;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.pop-menu-item:hover {
  background-color: #ee2e2447;
}

.pop-menu-item p {
  margin: 0px 0px 0px 5px;
  vertical-align: middle;
}

.logout {
  position: absolute;
  bottom: 0px;
}

.top-bar-wrapper {
  position: fixed;
  min-width: 100%;
  top: 0px;
  height: 50px;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: solid rgba(51, 51, 51, 0.4) 1px;
  z-index: 2;
  background-color: #F5F5F5;
}

.top-bar-wrapper p {
  margin: 0 60px 0px 10px;
}

.top-details-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.login-main-container {
  display: -webkit-flex;
  display: flex;
  min-height: 100vh;
  -webkit-align-items: center;
          align-items: center;
}

.login-wrapper {
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.login-form {
  min-width: 350px;
  padding: 20px;
  box-shadow: 0 6px 4px 0 rgba(0,0,0,.16);
  background-color: white;
}

.logo-wrapper-dav {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.logo-wrapper-dav img {
  width: 250px;
  height: 100px
}

.login-form input {
  margin-top: 10px;
  height: 45px;
}

.buttons-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 20px;
}

.btn-login {
  width: 100%;
  margin-top: 10px;
  height: 45px;
}

.btn-reset {
  width: 100%;
  margin-top: 10px;
  height: 45px;
}

.construction-banner {
  position: absolute;
  top: 0px;
  width: 100%;
  margin-left: -15px;
  margin-right: -15px;
}

.construction-alert {
  padding:40px 40px 40px 40px;
}
body {
    background-color: #F5F5F5;
    position: relative;
}

.loading-screen {
    width: 100%;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-items: center;
            align-items: center;
}

p {
    font-family: 'Open Sans', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
}

.main-container-dash {
    padding-top: 65px;
}

.img-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 20px;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.svg-image {
    height: 200px;
    width: 600px;
    margin-bottom: 20px;
}

.right-col-dash {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    border-radius: 20px;

    padding: 10px;
    box-shadow: 0 6px 4px 0 rgba(0,0,0,.16);
    background-color: white;
}

.right-col-dash p {
    margin: 0;
    text-align: center;
}
.main-container {
  display: -webkit-flex;
  display: flex;
  min-height: 100vh;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 65px;
}

.create-wrapper {
  position: relative;
  padding: 20px;
  max-height: 600px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px 0px;
  min-width: 450px;
  background-color: white;
  max-width: 450px;
}

.days-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.day-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 5px 5px 5px 5px;
  width: 80px;
}

label {
  color: #333;
  font-size: 13px;
  font-weight: 600;
  margin-top: 10px;
}

.days-title {
  width: 100%;
}

.button-wrapper {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 20px;
}

.btn-create {
  width: 150px;
}
.create-wrapper-job {
    position: relative;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px 0px;
    background-color: white;
    margin-bottom: 20px;
    max-width: 750px;
}

.terms-row {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.terms-row p {
    margin: 0px;
}

.submit-wrapper {
    width: 100%;
}

.submit-button {
    width: 150px;
}

.user-container {
  margin-top: 70px;
}


.update-wrapper {
  padding: 20px;
}

.center-toggles {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

span {
  margin-right: 10px;
}

.update-button-wrapper {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: 10px;
}

.input-group-prepend {
  margin-right: -11px;
}

.expanded-user {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 30px;
}

.button-wrapper {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 5px;
}

.button-wrapper button {
  min-width: 200px;
  height: 35px
}
.create-wrapper-settings {
  position: relative;
    padding: 20px;
    max-height: 750px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px 0px;
    min-width: 850px;
    background-color: white;
}

.option-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.option {
  position: relative;
  border-radius: 20px;
  background-color: rgb(43, 114, 221);
  color: white;
  margin: 5px;
  padding: 5px 10px 5px 10px;
}

.option p {
  margin: 0px;
  font-size: 13px;
}

.delete-icon {
  position: absolute;
  top: -10px;
  right: 0px;
  color: rgb(218, 20, 20);
  cursor: pointer;
}

.input-wrapper {
  position: relative;
}

.add-icon {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  color: green;
}
.job-container {
  margin-top: 70px;
}

.expanded-row {
  padding: 10px 20px 10px 20px;
  border-bottom: solid 1px rgba(51, 51, 51, 0.527);
}

.expanded-row p {
  width: 100%;
  margin-bottom: 0px;
  font-size: 14px;
}

label {
  font-size: 16px;
  width: 100%;
}

.extra-margin {
  margin-left: 15px;
  margin-top: 0px;
}

.admin-notes-col {
  position: relative;
}

.edit-icon {
 cursor: pointer;
 margin-left: 5px;
}

.export-btn-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin: 15px 0px 15px 0px;
}
