
.main-container-dash {
    padding-top: 65px;
}

.img-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.svg-image {
    height: 200px;
    width: 600px;
    margin-bottom: 20px;
}

.right-col-dash {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 20px;

    padding: 10px;
    box-shadow: 0 6px 4px 0 rgba(0,0,0,.16);
    background-color: white;
}

.right-col-dash p {
    margin: 0;
    text-align: center;
}