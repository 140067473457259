.job-container {
  margin-top: 70px;
}

.expanded-row {
  padding: 10px 20px 10px 20px;
  border-bottom: solid 1px rgba(51, 51, 51, 0.527);
}

.expanded-row p {
  width: 100%;
  margin-bottom: 0px;
  font-size: 14px;
}

label {
  font-size: 16px;
  width: 100%;
}

.extra-margin {
  margin-left: 15px;
  margin-top: 0px;
}

.admin-notes-col {
  position: relative;
}

.edit-icon {
 cursor: pointer;
 margin-left: 5px;
}

.export-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0px 15px 0px;
}