.user-container {
  margin-top: 70px;
}


.update-wrapper {
  padding: 20px;
}

.center-toggles {
  display: flex;
  align-items: center;
}

span {
  margin-right: 10px;
}

.update-button-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 10px;
}

.input-group-prepend {
  margin-right: -11px;
}

.expanded-user {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 30px;
}

.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px;
}

.button-wrapper button {
  min-width: 200px;
  height: 35px
}